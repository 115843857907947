import axios from 'axios';
import authHeader from './auth-header';

const API_URL = window._env_.REACT_APP_API_HOST + '/v1/';

class TrainingsService {
  getTrainings() {
    return axios.get(API_URL + 'trainings', { headers: authHeader() });
  }
  getCompletedTrainings(language = 'English') {
    return axios.get(API_URL + 'user-training-packages?status=PASSED,FAILED&language=' + language, { headers: authHeader() });
  }
  getActiveTrainings(language = 'English') {
    return axios.get(API_URL + 'user-training-packages?status=CREATED,STARTED&language=' + language, { headers: authHeader() });
  }
  getExpTrainings(language = 'English') {
    return axios.get(API_URL + 'user-training-packages?status=EXPIRED&language=' + language, { headers: authHeader() });
  }
  startTraining(id, language = 'English') {
    return axios.post(API_URL + 'user-training-packages/' + id + '/start?language=' + language, {}, { headers: authHeader() });
  }
  getTrainingModules(id, language = 'English') {
    return axios.get(API_URL + 'training-packages/' + id + '/modules?language=' + language, { headers: authHeader() });
  }

  registerFishing(id) {
    return axios.post(API_URL + 'simulations/' + id + '/follow-link', {});
  }
  finishTraining(id, language, answers) {
    return axios.post(API_URL + 'user-training-packages/' + id + '/finish', { language: language, answers: answers }, { headers: authHeader() });
  }

  enable(id, active) {
    return axios.post(API_URL + 'trainings/enable', { active: active, ids: [id] }, { headers: authHeader() });
  }

  getModules(id) {
    return axios.get(API_URL + 'trainings/' + id + '/modules', { headers: authHeader() });
  }

  getAllModules() {
    return axios.get(API_URL + 'trainings/modules', { headers: authHeader() });
  }

  getModulePreview(trainingId, moduleId) {
    return axios.get(API_URL + 'trainings/' + trainingId + '/modules/' + moduleId, { headers: authHeader() });
  }

  enableModule(trainingId, moduleId, active) {
    return axios.post(API_URL + 'trainings/' + trainingId + '/modules/enable', { active: active, ids: [moduleId] }, { headers: authHeader() });
  }

  // Packages:
  getPackages() {
    return axios.get(API_URL + 'training-packages', { headers: authHeader() });
  }
  getPackage(id) {
    return axios.get(API_URL + 'training-packages/' + id, { headers: authHeader() });
  }
  addPackage(packageBody) {
    return axios.post(API_URL + 'training-packages/', packageBody, { headers: authHeader() });
  }
  updatePackage(id, packageBody) {
    return axios.put(API_URL + 'training-packages/' + id, packageBody, { headers: authHeader() });
  }
  publishPackage(id) {
    return axios.post(API_URL + 'training-packages/' + id + '/publish', {}, { headers: authHeader() });
  }
  archivePackage(id) {
    return axios.post(API_URL + 'training-packages/' + id + '/archive', {}, { headers: authHeader() });
  }
}

export default new TrainingsService();
