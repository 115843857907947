import React, { useState } from 'react'
import './styles.css'
import { Button, LinearProgress, TextField, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import Spacer from 'src/common/components/Spacer'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import trainingsService from 'src/services/trainings.service'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc"

const SearchInput = styled(TextField)(() => ({
  marginTop: '20px',
  // marginLeft: '20px',
  marginBottom: '20px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    border: '1px solid #E7E7EE',
    background: '#fff'
  }
}))

const icons = [
  '/images/cat-img-1.png',
  '/images/cat-img-2.png',
  '/images/cat-img-3.png',
  '/images/cat-img-4.png',
  '/images/cat-img-5.png',
  '/images/cat-img-6.png',
]
const images = [
  {
    image: '/images/trainingcard1.png',
    size: 79,
  },
  {
    image: '/images/trainingcard2.png',
    size: 98,
  },
  {
    image: '/images/trainingcard3.png',
    size: 122,
  },
  {
    image: '/images/trainingcard4.png',
    size: 100,
  },
  {
    image: '/images/trainingcard5.png',
    size: 136,
  },
  {
    image: '/images/trainingcard6.png',
    size: 128,
  },
]

const colors = [
  '#FF67E1',
  '#0179FF',
  '#A374F6',
  '#A374F6',
  '#0179FF',
  '#FFC700',
]

const Trainings = () => {
  dayjs.extend(utc)

  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const [tab, setTab] = useState('active')
  const [trainings, setTrainings] = useState([])
  const [trainingsFiltred, setTrainingsFiltred] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalActive, setTotalActive] = useState(0)


  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  const handleWindowResizeEvent = () => {
    setIsMobile(window.innerWidth < 1024)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowResizeEvent)
  }, [])

  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)
    trainingsService.getActiveTrainings().then((resp) => {
      setLoading(false)
      setTrainings(resp.data)
      setTrainingsFiltred(resp.data)
    })
  }, [])

  useEffect(() => {
    setTrainingsFiltred(!search ? trainings : trainings.filter(el => el.name.toLowerCase().includes(search.toLowerCase())))
  }, [search])

  useEffect(() => {
    const currentLang = localStorage.getItem('lang')
      ? localStorage.getItem('lang') == 'pl'
        ? 'Polish'
        : 'English'
      : 'English'
    setLoading(true)
    tab == 'active'
      ? trainingsService.getActiveTrainings(currentLang).then((resp) => {
        setLoading(false)
        setTrainings(resp.data)
        setTrainingsFiltred(!search ? resp.data : resp.data.filter(el => el.name.toLowerCase().includes(search.toLowerCase())))
        setTotalActive(resp.data.length)
      })
      :
      tab == 'expired' ?
        trainingsService.getExpTrainings(currentLang).then(resp => {
          setLoading(false)
          setTrainings(resp.data)
          setTrainingsFiltred(!search ? resp.data : resp.data.filter(el => el.name.toLowerCase().includes(search.toLowerCase())))
        })
        : trainingsService.getCompletedTrainings(currentLang).then(resp => {
          setLoading(false)
          setTrainings(resp.data)
          setTrainingsFiltred(!search ? resp.data : resp.data.filter(el => el.name.toLowerCase().includes(search.toLowerCase())))
        })
  }, [tab])


  return (
    <>
      <div style={{ maxWidth: '900px', margin: 'auto' }}>
        <div className='column'>
          <h1 style={{ textAlign: 'center' }}>{t('myTrainings')} </h1>
          <div className='row' style={{ justifyContent: 'center' }}>
            <SearchInput sx={{ width: isMobile ? 300 : 640 }} size='small' placeholder={t('search')} onChange={(e) => setSearch(e.target.value)} value={search} variant='outlined' />
          </div>

          <div className='row tabs'>
            <div className={`row row-center tab ${tab == 'active' && 'tab-active'}`} onClick={() => setTab('active')}>
              <p>{t('active')}</p>
              <p className='badge'>{totalActive || 0}</p>
            </div>
            <div className={`row row-center tab ${tab == 'completed' && 'tab-active'}`} onClick={() => setTab('completed')}>
              <p>{t('completed')}</p>
            </div>
            <div className={`row row-center tab ${tab == 'expired' && 'tab-active'}`} onClick={() => setTab('expired')}>
              <p>{t('expired')}</p>
            </div>

          </div>
          <div style={{ marginBottom: '36px' }}>
            {loading && <LinearProgress sx={{
              '&.MuiLinearProgress-root': {
                backgroundColor: '#6123ff3d',
                '& span.MuiLinearProgress-barColorPrimary': {
                  backgroundColor: '#6123FF'
                }
              },
            }} />}
          </div>

          <div className='row training-items'>
            {!trainingsFiltred.length && (
              <div className='column' style={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <svg style={{ marginTop: '100px', marginBottom: '16px' }} width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.25" d="M16.875 51.9922V77.5488C16.8735 78.3578 17.135 79.1453 17.6201 79.7927C20.7779 83.9947 34.1002 99.3672 60 99.3672C85.8998 99.3672 99.2221 83.9947 102.38 79.7927C102.865 79.1453 103.127 78.3578 103.125 77.5488V51.9922L60 74.9922L16.875 51.9922Z" fill="#E7E7EE" />
                  <path d="M3.75 45L60 15L116.25 45L60 75L3.75 45Z" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M88.125 112.5V60L60 45" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M103.125 51.9922V77.5488C103.127 78.3578 102.865 79.1453 102.38 79.7927C99.2221 83.9947 85.8998 99.3672 60 99.3672C34.1002 99.3672 20.7779 83.9947 17.6201 79.7927C17.135 79.1453 16.8735 78.3578 16.875 77.5488V51.9922" stroke="#E7E7EE" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p style={{ textAlign: 'center', color: '#76767F', maxWidth: '255px', lineHeight: '24px' }}>{t('noresults')}</p>

              </div>
            )}
            {trainingsFiltred.map((el, index) => (
              <div key={el.trainingPackageId + el.status + (el.completedAt || '')} style={{ backgroundColor: colors[index % 6] }} className='training-item column'>
                <div style={{ zIndex: 2 }} className='row row-center'>
                  <div className='training-item-cat'>{el.category}</div>
                  {/* <CategoryImage cat={el.category} /> */}
                  <img style={{ width: '14px' }} src={icons[index % 6]} alt="" />
                  <Spacer />
                  <div style={{ padding: '0px 10px', lineHeight: '24px', borderRadius: '6px', border: '1px solid #fff', fontSize: '10px' }}>{el.mandatory ? t('Mandatory') : 'Optional'}</div>
                </div>
                <Tooltip arrow={true} title={el.name}>
                  <h3 className='training-item-title text-shrink-2'>{el.name}</h3>
                </Tooltip>
                <div className='row training-item-stars'>{t('You earn')}: {el?.stars} {t('stars')}</div>
                <div className='row training-item-image'>
                  <img style={{ position: 'absolute', height: 'calc(100% - 10px)', maxHeight: 100, marginBottom: 10, flexShrink: 1, ...(isMobile && { marginTop: 15 }) }} src={images[index % 6]?.image} alt="" />
                </div>
                {/* <Spacer /> */}
                {['CREATED', 'STARTED', 'EXPIRED'].includes(el.status) && (
                  <div className='row row-center training-item-deadline'>
                    {el.endDate && <p style={{ lineHeight: '16px' }}>{t('Deadline')}:<br /> {dayjs.utc(el.endDate).format('YYYY MMM DD')}</p>}
                    <Spacer />
                    <Button
                      onClick={() => navigate(`/cards-v3?id=${el.trainingPackageId}&skipIntro=1`)}
                      sx={{
                        color: '#1B1B26',
                        background: '#fff',
                        textTransform: 'none',
                        fontWeight: '600',
                        fontSize: '14px',
                        '&:hover': {
                          background: '#eee'
                        }
                      }}
                      variant={'contained'}
                    >{el.status == 'STARTED' ? t('Continue') : t('Start')}</Button>
                  </div>
                )}

                {['PASSED', 'FAILED'].includes(el.status) && (
                  <div className='row row-center training-item-deadline-done'>
                    <p style={{ fontSize: '13px', lineHeight: '16px' }}>{t('completed')}:<br /> {dayjs.utc(el.completedAt).local().format('YYYY MMM DD HH:mm')}</p>
                    <Spacer />
                    {el.retrainingAllowed &&
                      <Button
                        onClick={() => navigate(`/cards-v3?id=${el.trainingPackageId}&skipIntro=1`)}
                        sx={{
                          color: '#1B1B26',
                          background: '#fff',
                          textTransform: 'none',
                          fontWeight: '600',
                          fontSize: '14px',
                          '&:hover': {
                            background: '#eee'
                          }
                        }}
                        variant={'contained'}
                      >{t('Repeat')}</Button>
                    }
                  </div>
                )}

              </div>

            ))}
          </div>

        </div>
      </div>
    </>
  )
}

export default Trainings
